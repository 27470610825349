<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 py-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >TopUp's</span
              >
            </h3>
          </div>

          <div class="card-body py-0">
            <div class="row mb-5">
              <div class="col-md-4">
                <div class="form-group mb-0">
                  <input
                    v-model="serialNumber"
                    type="text"
                    class="form-control"
                    placeholder="Serial number"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-block btn-light-primary font-weight-bold"
                  @click="getCardTopUps"
                >
                  {{ $t('GENERAL.SEARCH') }}
                </button>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3 d-flex justify-content-end">
                <router-link
                  to="/loyalty/top-up/new"
                  class="btn btn-primary font-weight-bolder"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/icons/Shopping/Wallet2.svg"
                    /> </span
                  >Add New
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="table-responsive">
              <table
                v-if="isCardTopUpsLoaded"
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-left text-uppercase">
                    <th class="px-6">Serial number</th>
                    <th>User</th>
                    <th>
                      <span
                        @click="orderCardByTotalTopUpsSaldo"
                        class="cursor-pointer"
                      >
                        <span
                          class="pt-2"
                          :class="{
                            'text-primary': cardTotalTopUpsOrder.isActive,
                          }"
                        >
                          Total amount</span
                        >
                        <span
                          class="svg-icon svg-icon-sm pl-2"
                          :class="{
                            'svg-icon-primary': cardTotalTopUpsOrder.isActive,
                          }"
                        >
                          <inline-svg
                            v-if="cardTotalTopUpsOrder.query === 'asc'"
                            src="media/svg/icons/Navigation/Down-2.svg"
                          />
                          <inline-svg
                            v-else
                            src="media/svg/icons/Navigation/Up-2.svg"
                          />
                        </span>
                      </span>
                    </th>
                    <th>Created At</th>
                    <th>STATUS</th>
                    <th class="text-right pr-6">{{ $t('GENERAL.ACTIONS') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(fTopUp, key) in topUps" :key="key">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      {{ fTopUp.serialNumber }}
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      {{ fTopUp.user }}
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span v-if="fTopUp.totalAmount !== 0">{{
                        fTopUp.totalAmount | currencyFormat
                      }}</span>
                      <span v-else>0 €</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span> {{ fTopUp.createdAt | formatDate }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span> {{ fTopUp.status }}</span>
                    </td>
                    <td
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <button
                        @click="toggleTopUpCardsModal(fTopUp.serialNumber)"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Text/Align-justify.svg"
                          />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-else
                class="col-12 d-flex align-items-center justify-content-center pb-5"
                style="height: 540px"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
              <div
                class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
              >
                <b-pagination-nav
                  :link-gen="linkGen"
                  :number-of-pages="pagination.totalPages"
                  use-router
                  size="lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="top_up_cards_modal"
          size="xl"
          hide-header
          hide-footer
          centered
          style="max-height: 700px; overflow: scroll"
        >
          <div>
            <button
              class="btn btn-light-primary btn-block mb-3"
              @click="$bvModal.hide('top_up_cards_modal')"
            >
              Close
            </button>
            <div class="table-responsive">
              <table
                v-if="isTopUpsLoaded"
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-left text-uppercase">
                    <th class="px-6">Serial number</th>
                    <th>{{ $t('GENERAL.AMOUNT') }}</th>
                    <th>Status</th>
                    <th class="text-right pr-6">Created at</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(topUp, key) in cardTopUps" :key="key">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      {{ topUp.serialNumber }}
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      {{ topUp.price }} €
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="topUp.status === 'bonus'"
                        class="label label-lg label-light-warning label-inline"
                        >bonus</span
                      >
                      <span
                        v-if="topUp.status === 'adminTopUp'"
                        class="label label-lg label-light-primary label-inline"
                        >admin top up</span
                      >
                      <span
                        v-if="topUp.status === 'card'"
                        class="label label-lg label-light-primary label-inline"
                        >card</span
                      >
                      <span
                        v-if="topUp.status === 'system'"
                        class="label label-lg label-light-primary label-inline"
                        >system</span
                      >
                      <span
                        v-if="topUp.status === 'bonus-admin'"
                        class="label label-lg label-light-warning label-inline"
                        >bonus-admin</span
                      >
                      <span
                        v-if="topUp.status === 'admin'"
                        class="label label-lg label-light-success label-inline"
                        >admin</span
                      >
                      <span
                        v-if="topUp.status === 'bonus-verification'"
                        class="label label-lg label-light-warning label-inline"
                        >bonus-verification</span
                      >
                      <span
                        v-if="topUp.status === 'inProgress'"
                        class="label label-lg label-light-warning label-inline"
                        >in progress</span
                      >
                      <span
                        v-if="topUp.status === 'success'"
                        class="label label-lg label-light-success label-inline"
                        >success</span
                      >
                      <span
                        v-if="topUp.status === 'pos'"
                        class="label label-lg label-light-success label-inline"
                        >pos</span
                      >
                      <span
                        v-if="topUp.status === 'bonus-pos'"
                        class="label label-lg label-light-warning label-inline"
                        >bonus-pos</span
                      >
                    </td>
                    <td
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      {{ topUp.createdAt | formatDateTime }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-else
                class="col-12 d-flex align-items-center justify-content-center pb-5"
                style="height: 540px"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import getYear from 'date-fns/getYear'

export default {
  name: 'LoyaltyTopUpList',
  data() {
    return {
      topUps: [],
      cardTopUps: [],
      isTopUpsLoaded: false,
      isCardTopUpsLoaded: false,
      cardTotalTopUpsOrder: {
        query: 'desc',
        isActive: false,
      },
      serialNumber: '',
      pagination: {
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 30,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty TopUp List', route: '' },
    ])
    this.getCardTopUps()
  },
  watch: {
    '$route.query.page'(newPage) {
      this.pagination.currentPage = newPage || 1
      this.getCardTopUps()
    },
  },
  methods: {
    getCardTopUps() {
      this.isCardTopUpsLoaded = false
      let searchQuery = ''
      if (this.serialNumber) {
        searchQuery = `&card.serialNumber=${this.serialNumber}`
      }
      this.topUps = []
      axios
        .get(
          `https://loyalty.vendingtycoon.eu/api/v1/card_top_ups?order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}${searchQuery}`
        )
        .then(({ data }) => {
          this.isTopUpsLoaded = true
          this.pagination.totalItems = data['hydra:totalItems']
          this.pagination.totalPages = Math.ceil(
            this.pagination.totalItems / this.pagination.itemsPerPage
          )
          data['hydra:member'].forEach((topUp) => {
            this.topUps.push({
              serialNumber: topUp.card.serialNumber,
              totalAmount: parseFloat(topUp.price),
              topUps: [topUp],
              user: topUp.card.user?.firstName + ' ' + topUp.card.user?.surName,
              createdAt: topUp.createdAt,
              status: topUp.status,
            })
          })
          this.isCardTopUpsLoaded = true
        })
    },
    toggleTopUpCardsModal(key) {
      this.isTopUpsLoaded = false
      this.cardTopUps = []
      axios
        .get(
          `https://loyalty.vendingtycoon.eu/api/v1/card_top_ups?card.serialNumber=${key}&order[createdAt]=desc&itemsPerPage=1000&page=1`
        )
        .then(({ data }) => {
          data['hydra:member'].forEach((topUp) => {
            this.cardTopUps.push({
              serialNumber: topUp.card.serialNumber,
              price: parseFloat(topUp.price).toFixed(2),
              createdAt: topUp.createdAt,
              status: topUp.status,
            })
            this.isTopUpsLoaded = true
          })
        })

      this.$root.$emit('bv::show::modal', 'top_up_cards_modal')
    },

    orderCardByTotalTopUpsSaldo() {
      this.cardTotalTopUpsOrder.isActive = true

      if (this.cardTotalTopUpsOrder.query === 'asc')
        this.cardTotalTopUpsOrder.query = 'desc'
      else this.cardTotalTopUpsOrder.query = 'asc'

      if (this.cardTotalTopUpsOrder.query === 'asc') {
        this.topUps.sort((a, b) => a.totalAmount - b.totalAmount)
      } else {
        this.topUps.sort((a, b) => b.totalAmount - a.totalAmount)
      }
    },
    displayYear(date) {
      return getYear(new Date(date))
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--card-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
